import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import { GRADIENT_LANDING, GRADIENT_SEARCH_HEADER } from '@/designSystem/theme';

type Props = {
  verticalPadding: number;
};

export default makeStyles<Props>()(
  ({ palette, breakpoints }, { verticalPadding }) => ({
    container: {
      position: 'relative',
      margin: '0 auto',
      padding: `${rem(verticalPadding / 2)} 0`,
      maxWidth: rem(1440),
      [breakpoints.up('lg')]: {
        padding: `${rem(verticalPadding)} 0`,
        width: '100%',
        maxWidth: rem(1440),
      },
    },
    withOverflow: {
      overflowX: 'clip',
    },
    white: {
      backgroundColor: palette.common.white,
    },
    greenGradient: {
      background: GRADIENT_LANDING,
    },
    green: {
      backgroundColor: palette.supporting.green.light,
    },
    blue: {
      backgroundColor: palette.supporting.sky.light,
    },
    linear: {
      background: GRADIENT_SEARCH_HEADER,
    },
    lightGrey: {
      backgroundColor: palette.neutral.light,
    },
  })
);
