import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles<{
  canStopRedirectToRating: boolean;
}>()(({ palette }, { canStopRedirectToRating }) => ({
  container: {
    cursor: canStopRedirectToRating ? 'unset' : 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: rem(12),
    padding: `${rem(4)} ${rem(8)}`,
  },
  stars: {
    display: 'flex',
    gap: rem(2),
    color: palette.supporting.amber[500],
  },
}));
